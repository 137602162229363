import React, { useRef, useState, useEffect } from "react";
import Short from "./Short";
import data from "../data/data.json";
import video from "../../assets/test.mp4";
import axios from "axios";

function ShortContainer({ user }) {
  const shortContainerRef = useRef();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Функция для загрузки видео через API
    const fetchVideos = async () => {
      if (!user || !user.id) {
        // Если нет пользователя, используем тестовые данные
        setVideos(data);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(`/api/user-video/${user.id}`);

        // Объединяем избранные и обычные видео в один массив
        const allVideos = [
          ...(response.data.favorite_videos || []).map((v) => ({
            ...v,
            is_favorite: true,
          })),
          ...(response.data.non_favorite_videos || []).map((v) => ({
            ...v,
            is_favorite: false,
          })),
        ];

        if (allVideos.length > 0) {
          setVideos(allVideos);
        } else {
          // Если нет видео, используем тестовые данные
          setVideos(data);
        }

        setLoading(false);
      } catch (error) {
        console.error("Ошибка при загрузке видео:", error);
        setError("Не удалось загрузить видео");
        // В случае ошибки используем тестовые данные
        setVideos(data);
        setLoading(false);
      }
    };

    fetchVideos();
  }, [user]);

  return (
    <>
      <section ref={shortContainerRef} className="short-container">
        {loading ? (
          <div className="loading">Загрузка видео...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          videos.map((short) => (
            <Short
              key={short.id}
              shortContainerRef={shortContainerRef}
              short={short}
              video={video}
            />
          ))
        )}
      </section>

      <div className="navigation-container">
        <div
          onClick={() => {
            shortContainerRef.current.scrollTo(
              0,
              shortContainerRef.current.scrollTop - window.innerHeight
            );
          }}
          className="nav-up"
        >
          <ion-icon name="arrow-up-outline"></ion-icon>
        </div>
        <div
          onClick={() => {
            shortContainerRef.current.scrollTo(
              0,
              shortContainerRef.current.scrollTop + window.innerHeight
            );
          }}
          className="nav-down"
        >
          <ion-icon name="arrow-down-outline"></ion-icon>
        </div>
      </div>
    </>
  );
}

export default ShortContainer;
