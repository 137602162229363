import React from "react";
import VideoList from "../../Common/VideoList/VideoList";
import s from "./UserManager.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  DollarOutlined,
  HeartTwoTone,
  TeamOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import ShortContainer from "../../comp/ShortContainer";

const UserManager = () => {
  const [videos, setVideos] = useState([]);
  const user = useSelector((state) => state.user);
  const stats = useSelector((state) => state.stats);

  // useEffect(() => {
  //   const fetchVideos = async () => {
  //     try {
  //       const response = await fetch(
  //         `https://blogerverse.com/api/user-video/${user.id}`
  //       );
  //       const data = await response.json();

  //       // Объединяем избранные и обычные видео в один массив
  //       const allVideos = [
  //         ...(data.favorite_videos || []).map((v) => ({
  //           ...v,
  //           is_favorite: true,
  //         })),
  //         ...(data.non_favorite_videos || []).map((v) => ({
  //           ...v,
  //           is_favorite: false,
  //         })),
  //       ];

  //       setVideos(allVideos);
  //     } catch (error) {
  //       console.error("Ошибка при загрузке видео:", error);
  //     }
  //   };

  //   fetchVideos();
  // }, [user.id]);

  const handleSetFavorite = async (videoId) => {
    try {
      await axios.patch(`https://blogerverse.com/api/user-video/${user.id}`, {
        video_id: videoId,
      });

      // Обновляем состояние после успешного запроса
      setVideos((prevVideos) =>
        prevVideos.map((video) =>
          video.id === videoId
            ? { ...video, is_favorite: !video.is_favorite }
            : video
        )
      );
    } catch (error) {
      console.error("Ошибка при обновлении избранного видео:", error);
    }
  };

  // const formatNumber = (num) => {
  //   if (num >= 1_000_000_000) return `${(num / 1_000_000_000).toFixed(1)}B`;
  //   if (num >= 1_000_000) return `${(num / 1_000_000).toFixed(1)}M`;
  //   if (num >= 1_000) return `${(num / 1_000).toFixed(1)}K`;
  //   return num.toString();
  // };

  return (
    <div className={s.container}>
      {/* Videos Section */}
      <div className={s.videosSection}>
        <h3 className={s.sectionTitle}>Your Videos</h3>
        <ShortContainer user={user} />
      </div>

      {/* Stats Section */}
      <div className={s.statsSection}>
        <h3 className={s.sectionTitle}>User Statistics</h3>

        {/* Balance Stats */}
        <div className={s.statsCategory}>
          <h4 className={s.categoryTitle}>
            <DollarOutlined /> Balance
          </h4>
          <div className={s.statsGrid}>
            <div className={s.statItem}>
              <span className={s.statLabel}>Current Coins</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.coin_count)} */}
              </span>
            </div>
            <div className={s.statItem}>
              <span className={s.statLabel}>Total Coins</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.total_coins)} */}
              </span>
            </div>
            <div className={s.statItem}>
              <span className={s.statLabel}>Coins per Second</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.income_coin_second)} */}
              </span>
            </div>
          </div>
        </div>

        {/* Engagement Stats */}
        <div className={s.statsCategory}>
          <h4 className={s.categoryTitle}>
            <HeartTwoTone /> Engagement
          </h4>
          <div className={s.statsGrid}>
            <div className={s.statItem}>
              <span className={s.statLabel}>Current Likes</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.like_count)} */}
              </span>
            </div>
            <div className={s.statItem}>
              <span className={s.statLabel}>Total Likes</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.total_likes)} */}
              </span>
            </div>
            <div className={s.statItem}>
              <span className={s.statLabel}>Likes per Second</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.income_like_second)} */}
              </span>
            </div>
          </div>
        </div>

        {/* Social Stats */}
        <div className={s.statsCategory}>
          <h4 className={s.categoryTitle}>
            <TeamOutlined /> Social
          </h4>
          <div className={s.statsGrid}>
            <div className={s.statItem}>
              <span className={s.statLabel}>Friends</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.friends_count)} */}
              </span>
            </div>
            <div className={s.statItem}>
              <span className={s.statLabel}>Invites</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.invites_count)} */}
              </span>
            </div>
          </div>
        </div>

        {/* Activity Stats */}
        <div className={s.statsCategory}>
          <h4 className={s.categoryTitle}>
            <ClockCircleOutlined /> Activity
          </h4>
          <div className={s.statsGrid}>
            <div className={s.statItem}>
              <span className={s.statLabel}>Clicks</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.clicks_count)} */}
              </span>
            </div>
            <div className={s.statItem}>
              <span className={s.statLabel}>Clicks Cooldown</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.time_refresh)}s */}
              </span>
            </div>
            <div className={s.statItem}>
              <span className={s.statLabel}>Items Bought</span>
              <span className={s.statValue}>
                {/* {formatNumber(stats.items_bought)} */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManager;
