import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import {
  PlayCircleOutlined,
  PauseOutlined,
  SoundOutlined,
  AudioMutedOutlined,
} from "@ant-design/icons";
import s from "./VideoTest.module.css";

// Импортируем локальное видео
import videoFile from "../../assets/test.mp4";

const VideoTest = () => {
  // Состояние для нативного плеера
  const [nativePlaying, setNativePlaying] = useState(false);
  const [nativeMuted, setNativeMuted] = useState(true);
  const [nativeError, setNativeError] = useState("");
  const nativeVideoRef = useRef(null);

  // Состояние для React Player
  const [reactPlaying, setReactPlaying] = useState(false);
  const [reactMuted, setReactMuted] = useState(true);
  const [reactError, setReactError] = useState("");
  const reactPlayerRef = useRef(null);

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isTelegramWebApp = window.Telegram && window.Telegram.WebApp;

  // Функция для переключения воспроизведения нативного видео
  const toggleNativeVideo = () => {
    if (!nativeVideoRef.current) return;

    try {
      setNativeError("");

      if (nativePlaying) {
        nativeVideoRef.current.pause();
        setNativePlaying(false);
      } else {
        // Включаем звук при воспроизведении
        nativeVideoRef.current.muted = false;
        setNativeMuted(false);

        nativeVideoRef.current
          .play()
          .then(() => {
            setNativePlaying(true);
            console.log("Нативное видео воспроизводится");
          })
          .catch((err) => {
            setNativePlaying(false);
            setNativeError(`Ошибка: ${err.message}`);
            console.error("Ошибка нативного видео:", err);
          });
      }
    } catch (error) {
      setNativePlaying(false);
      setNativeError(`Ошибка: ${error.message}`);
    }
  };

  // Функция для переключения воспроизведения React Player
  const toggleReactVideo = () => {
    try {
      setReactError("");

      if (reactPlaying) {
        setReactPlaying(false);
      } else {
        // Включаем звук при воспроизведении
        setReactMuted(false);
        setReactPlaying(true);
      }
    } catch (error) {
      setReactPlaying(false);
      setReactError(`Ошибка: ${error.message}`);
    }
  };

  return (
    <div className={s.videoTestPage}>
      <h1>Тестирование видео</h1>
      <p className={s.deviceInfo}>
        Устройство: {isIOS ? "iOS" : "Другое"} | В Telegram:{" "}
        {isTelegramWebApp ? "Да" : "Нет"}
      </p>

      <div className={s.videoContainers}>
        {/* Нативный HTML5 видеоплеер */}
        <div className={s.videoSection}>
          <h2>Нативный HTML5 видеоплеер</h2>

          <div className={s.playerContainer}>
            <div className={s.videoWrapper} onClick={toggleNativeVideo}>
              <video
                ref={nativeVideoRef}
                className={s.video}
                src={videoFile}
                muted={nativeMuted}
                playsInline
                preload="auto"
                loop
              />

              <button
                className={s.playButton}
                type="button"
                onClick={toggleNativeVideo}
              >
                {nativePlaying ? (
                  <PauseOutlined className={s.playIcon} />
                ) : (
                  <PlayCircleOutlined className={s.playIcon} />
                )}
              </button>

              {!nativePlaying && (
                <div className={s.playOverlay}>Нажмите для воспроизведения</div>
              )}

              {nativeError && (
                <div className={s.errorMessage}>{nativeError}</div>
              )}
            </div>

            <div className={s.controls}>
              <p>Статус: {nativePlaying ? "Воспроизводится" : "Остановлено"}</p>
              <p>Звук: {nativeMuted ? "Выключен" : "Включен"}</p>
            </div>
          </div>
        </div>

        {/* React Player */}
        <div className={s.videoSection}>
          <h2>React Player</h2>

          <div className={s.playerContainer}>
            <div className={s.videoWrapper} onClick={toggleReactVideo}>
              <ReactPlayer
                ref={reactPlayerRef}
                url={videoFile}
                className={s.reactPlayer}
                width="100%"
                height="100%"
                playing={reactPlaying}
                muted={reactMuted}
                playsinline
                loop
                config={{
                  file: {
                    attributes: {
                      playsInline: true,
                      preload: "auto",
                      controlsList: "nodownload",
                    },
                  },
                }}
              />

              <button
                className={s.playButton}
                type="button"
                onClick={toggleReactVideo}
              >
                {reactPlaying ? (
                  <PauseOutlined className={s.playIcon} />
                ) : (
                  <PlayCircleOutlined className={s.playIcon} />
                )}
              </button>

              {!reactPlaying && (
                <div className={s.playOverlay}>Нажмите для воспроизведения</div>
              )}

              {reactError && <div className={s.errorMessage}>{reactError}</div>}
            </div>

            <div className={s.controls}>
              <p>Статус: {reactPlaying ? "Воспроизводится" : "Остановлено"}</p>
              <p>Звук: {reactMuted ? "Выключен" : "Включен"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTest;
