import Footer from "./Components/Footer/Footer";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { store } from "./Redux/store";
import MainPage from "./Components/Main";
import Updates from "./Components/Updates/Updates";
import Wardrobe from "./Components/Main/Wardrobe";
import Profile from "./Components/Profile/Profile";
import Quests from "./Components/Quests/Quests";
import Shop from "./Components/Shop/Shop";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import setUser from "./Redux/user/userReducer";
import s from "./App.module.css";
import GenerateVideo from "./Components/VideoGenerate/VideoUser";
import { useSelector } from "react-redux";
import PCRedirect from "./Components/PCRedirect/PCRedirect";
import VideoTest from "./Components/VideoTest/VideoTest";
import axios from "axios";

function App() {
  const dispatch = useDispatch();
  const [isAllowedDevice, setIsAllowedDevice] = useState(false);
  const [isAllowedUser, setIsAllowedUser] = useState(false);
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const user = useSelector((state) => state.user);

  const WHITELIST = [
    420530253, 361104283, 1037009755, 1490995234, 659113520, 354763867,
    1889621409, 839147200, 297470412,
  ];

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const { initData } = retrieveLaunchParams();
        // Проверяем, открыто ли приложение в Telegram WebApp
        const isTG = Boolean(initData);
        setIsTelegramWebApp(isTG);

        if (isTG) {
          // Если в Telegram, проверяем тип устройства
          const userAgent =
            navigator.userAgent || navigator.vendor || window.opera;
          const iOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
          const android = /android/i.test(userAgent);
          const isMobileDevice = iOS || android;

          setIsAllowedDevice(isMobileDevice);

          // Получаем ID пользователя из initData
          if (initData && initData.user) {
            const { id } = initData.user;
            dispatch(setUser(id, "default"));
            setIsAllowedUser(WHITELIST.includes(Number(id)));
          } else {
            console.warn("User data not found in initData");
            setIsAllowedUser(false);
          }
        }
      } catch (error) {
        console.error("Error checking access:", error);
        setIsTelegramWebApp(false);
        setIsAllowedUser(false);
      }
    };

    checkAccess();
  }, []); // Убрали user из зависимостей, так как теперь получаем ID из initData

  const [activeWard, setActiveWard] = useState(false);

  useEffect(() => {
    const fetchLaunchParams = async () => {
      try {
        const { initData } = retrieveLaunchParams();
        // window.alert("InitData received: " + JSON.stringify(initData));
        let userId = null;

        if (initData && initData.user) {
          const { id } = initData.user;
          userId = id;
          // window.alert("User ID from Telegram: " + id);

          try {
            // Пытаемся создать пользователя
            const response = await axios.post(
              "https://blogerverse.com/api/create-user/",
              {
                telegram_id: id,
              }
            );
            // window.alert("New user created successfully: " + id);
            dispatch(setUser(id, "default")); // Dispatch после успешного создания
          } catch (createError) {
            // Если пользователь уже существует, это не критическая ошибка
            window.alert(
              "User exists or creation error: " + createError.message
            );
            dispatch(setUser(id, "default")); // Dispatch даже если пользователь существует
          }
        } else {
          // window.alert("No Telegram data, using default user");
          dispatch(setUser(5, "Default")); // Фолбэк на дефолтного пользователя
        }
      } catch (error) {
        // window.alert("Main error: " + error.message);
        dispatch(setUser(5, "Default")); // Фолбэк на дефолтного пользователя
      }
    };

    fetchLaunchParams();
  }, [dispatch]);

  // Если не в Telegram WebApp - показываем страницу с QR-кодом
  if (!isTelegramWebApp) {
    return <PCRedirect isDeviceError={true} />;
  }

  // Если в Telegram, но не на мобильном - показываем ошибку устройства
  if (!isAllowedDevice) {
    return <PCRedirect isDeviceError={true} />;
  }

  // Если в Telegram на мобильном, но не в вайтлисте - показываем страницу тестирования
  if (!isAllowedUser) {
    return <PCRedirect isDeviceError={false} />;
  }

  return (
    <div className={s.app}>
      <BrowserRouter>
        {activeWard && <Wardrobe />}
        <Routes>
          <Route element={<MainPage activeWard={activeWard} />} path="/" />
          <Route element={<Quests />} path="/quests" />
          <Route element={<Updates />} path="/upgrade" />
          <Route element={<Profile />} path="/profile" />
          <Route element={<Shop />} path="/shop" />
          <Route element={<GenerateVideo />} path="/video" />
          <Route path="/video-test" element={<VideoTest />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer setActiveWard={setActiveWard} activeWard={activeWard} />
      </BrowserRouter>
    </div>
  );
}

const BricsApp = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
export default BricsApp;
