import React, { useState, useRef, useEffect } from "react";
import s from "./VideoPopup.module.css";
import {
  CloseOutlined,
  PlayCircleOutlined,
  PauseOutlined,
} from "@ant-design/icons";

const VideoPopup = ({ isOpen, onClose, videoUrl, buttons = [] }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const observerRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playVideo();
        } else {
          pauseVideo();
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, options);

    if (videoRef.current) {
      observerRef.current.observe(videoRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      pauseVideo();
      setIsMuted(true);
    }
  }, [isOpen]);

  const playVideo = async () => {
    if (videoRef.current) {
      try {
        await videoRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.error("Ошибка воспроизведения:", error);
      }
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  const toggleMute = async () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);

      if (!isPlaying) {
        await playVideo();
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className={s.overlay}>
      <div className={s.container}>
        <div className={s.videoContainer} onClick={togglePlay}>
          <video
            ref={videoRef}
            className={s.video}
            src={videoUrl}
            loop
            muted={isMuted}
            playsInline
            preload="auto"
          />

          <button className={s.playButton} type="button" onClick={togglePlay}>
            {isPlaying ? (
              <PauseOutlined className={s.playIcon} />
            ) : (
              <PlayCircleOutlined className={s.playIcon} />
            )}
          </button>

          {!isPlaying && (
            <div className={s.playOverlay}>Нажмите для воспроизведения</div>
          )}

          <button className={s.soundToggleButton} onClick={toggleMute}>
            {isMuted ? "Включить звук" : "Выключить звук"}
          </button>
        </div>

        <div className={s.buttonsContainer}>
          {buttons.map((button, index) => (
            <button
              key={index}
              onClick={button.onClick}
              className={`${s.button} ${button.className || ""}`}
              disabled={button.disabled}
            >
              {button.icon}
              {button.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
