import { useEffect, useRef, useState } from "react";

function Short({ short, shortContainerRef, video }) {
  const playPauseRef = useRef();
  const videoRef = useRef();

  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [isLiked, setIsLiked] = useState(short.reaction?.isLiked || false);
  const [isIOS, setIsIOS] = useState(false);
  const [hasError, setHasError] = useState(false);

  // Определяем, является ли устройство iOS
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOSDevice = /iphone|ipad|ipod/.test(userAgent);
    setIsIOS(isIOSDevice);
  }, []);

  // Получаем URL видео в зависимости от источника данных и устройства
  const getVideoUrl = () => {
    // Проверяем, пришли ли данные из API (имеют поле video_file)
    if (short.video_file) {
      // Для iOS используем специальный URL из API
      if (isIOS && short.video_file_ios) {
        console.log("Using iOS video URL:", short.video_file_ios);
        return short.video_file_ios;
      }
      return short.video_file;
    }

    // Для локальных данных (из data.json)
    if (short.videoUrl) {
      return short.videoUrl;
    }

    // Если передан отдельный параметр video
    if (video) {
      return video;
    }

    return "";
  };

  useEffect(() => {
    if (shortContainerRef.current) {
      shortContainerRef.current.addEventListener("scroll", handleVideo);
    }

    // Обработчики для проверки возможности воспроизведения
    const handleCanPlay = () => {
      console.log("Video can play");
      setHasError(false);
    };

    const handleError = (e) => {
      console.error("Video error:", e);
      setHasError(true);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("canplay", handleCanPlay);
      videoRef.current.addEventListener("error", handleError);
      setIsPlaying(!videoRef.current.paused);
      setIsMuted(videoRef.current.muted);
    }

    window.addEventListener("blur", () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    });

    window.addEventListener("focus", () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current
          .play()
          .catch((err) => console.error("Error playing video on focus:", err));
        setIsPlaying(true);
      }
    });

    // Очистка слушателей событий
    return () => {
      if (shortContainerRef.current) {
        shortContainerRef.current.removeEventListener("scroll", handleVideo);
      }
      if (videoRef.current) {
        videoRef.current.removeEventListener("canplay", handleCanPlay);
        videoRef.current.removeEventListener("error", handleError);
      }
      window.removeEventListener("blur", () => {});
      window.removeEventListener("focus", () => {});
    };
  }, [shortContainerRef]);

  async function handleVideo() {
    if (!videoRef.current) return;

    const videoTop = videoRef.current.getBoundingClientRect().top;

    if (videoTop > 0 && videoTop <= 150) {
      try {
        await videoRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.log("Ошибка воспроизведения видео:", error);
        setIsPlaying(false);
        videoRef.current.pause();
      }
    } else {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }

  const togglePlay = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play().catch((err) => {
        console.error("Error playing video:", err);
        // Если не удалось воспроизвести со звуком, пробуем без звука
        if (!isMuted) {
          videoRef.current.muted = true;
          setIsMuted(true);
          videoRef.current.play().catch((mutedErr) => {
            console.error("Error playing muted video:", mutedErr);
          });
        }
      });
      setIsPlaying(true);
    }
  };

  const toggleMute = () => {
    if (!videoRef.current) return;

    const newMutedState = !isMuted;
    videoRef.current.muted = newMutedState;
    setIsMuted(newMutedState);

    // Для iOS: если включаем звук и видео на паузе, запускаем воспроизведение
    if (!newMutedState && videoRef.current.paused) {
      videoRef.current.play().catch((err) => {
        console.error("Error playing video after unmute:", err);
        // Если не удалось воспроизвести со звуком, возвращаем muted
        videoRef.current.muted = true;
        setIsMuted(true);
      });
    }
  };

  // Получаем данные о пользователе и видео
  const getTitle = () => short.title || short.description || "";
  const getUsername = () =>
    short.username || (short.user ? short.user.username : "");
  const getProfileUrl = () =>
    short.profileUrl || (short.user ? short.user.profile_image : "");
  const getIsFollowed = () => short.isFollowed || false;

  return (
    <div className="reel">
      <div className="reel-video">
        <div className="video">
          <video
            ref={videoRef}
            onClick={togglePlay}
            disableRemotePlayback
            playsInline
            webkit-playsinline="true"
            x5-playsinline="true"
            loop
            muted={isMuted}
            preload="metadata"
            poster={short.preview_url || ""}
            src={getVideoUrl()}
          ></video>

          {hasError && (
            <div className="error-overlay">Не удалось загрузить видео</div>
          )}

          <div className="controls">
            <span onClick={togglePlay}>
              <ion-icon
                name={`${isPlaying ? "pause" : "play"}-outline`}
              ></ion-icon>
            </span>
            <span onClick={toggleMute}>
              <ion-icon
                name={`volume-${isMuted ? "mute" : "medium"}-outline`}
              ></ion-icon>
            </span>
          </div>

          <div
            ref={playPauseRef}
            onClick={() => {
              videoRef.current
                .play()
                .catch((err) => console.error("Error playing video:", err));
              setIsPlaying(true);
            }}
            className={`play-pause ${isPlaying ? "" : "show-play-pause"}`}
          >
            <ion-icon name="play-outline"></ion-icon>
          </div>

          {/* <div className="foot">
            <div className="title">{getTitle()}</div>
            <div className="user-info">
              <div>
                <img src={getProfileUrl()} alt="" />
                <span>{getUsername()}</span>
              </div>
              {!getIsFollowed() && <button>Follow</button>}
            </div>
          </div> */}
        </div>
        {/* <div className="reaction">
          <div
            className=""
            onClick={() => {
              setIsLiked(!isLiked);
            }}
          >
            {isLiked ? (
              <span className="like">
                <ion-icon name="heart"></ion-icon>
              </span>
            ) : (
              <span className="unlike">
                <ion-icon name="heart-outline"></ion-icon>
              </span>
            )}

            <span className="value">{short.reaction?.likes || "0"}</span>
          </div>
          <div>
            <ion-icon name="chatbubble-outline"></ion-icon>
            <span className="value">{short.reaction?.comments || "0"}</span>
          </div>
          <div>
            <ion-icon name="arrow-redo-outline"></ion-icon>
          </div>
          <div>
            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
          </div>
        </div> */}
      </div>
    </div>
  );
}

function isActiveVideo(videoRef) {
  if (!videoRef.current) return false;
  const videoTop = videoRef.current.getBoundingClientRect().top;
  return videoTop > 0 && videoTop <= 150;
}

export default Short;
