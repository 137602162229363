import { SET_STATS_USER_DATA } from "../types";

const initialState = {
  // Баланс
  like_count: 0,
  coin_count: 0,
  total_likes: 0,
  total_coins: 0,

  // Характеристики дохода
  income_like_second: 1,
  income_coin_second: 1,
  income_like_click: 0,
  income_coin_click: 0,

  // Клики
  time_refresh: 0,
  clicks_count: 0,

  // Социальные метрики
  friends_count: 0,
  invites_count: 0,

  // Предметы
  items_bought: 0,
};

export const userStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATS_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const setStatsUserData = ({
  like_count,
  coin_count,
  total_likes,
  total_coins,
  income_like_second,
  income_coin_second,
  income_like_click,
  income_coin_click,
  time_refresh,
  friends_count,
  clicks_count,
  items_bought,
  invites_count,
}) => ({
  type: SET_STATS_USER_DATA,
  payload: {
    like_count,
    coin_count,
    total_likes,
    total_coins,
    income_like_second,
    income_coin_second,
    income_like_click,
    income_coin_click,
    time_refresh,
    friends_count,
    clicks_count,
    items_bought,
    invites_count,
  },
});
