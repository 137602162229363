import React, { useEffect, useState } from "react";
import styles from "./PCRedirect.module.css";
import logo from "../../assets/Header/logo1.jpg";
import QR from "../../assets/Header/QR.jpg";

const PCRedirect = ({ isDeviceError }) => {
  const [deviceType, setDeviceType] = useState("unknown");
  const telegramAppUrl = "https://t.me/BlogerVerse";
  const telegramGroupUrl = "https://t.me/BlogerVerse";

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows|mac|linux/i.test(userAgent)) {
      setDeviceType("desktop");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setDeviceType("ios");
    } else if (/android/i.test(userAgent)) {
      setDeviceType("android");
    }
  }, []);

  const getMessage = () => {
    if (isDeviceError) {
      switch (deviceType) {
        case "desktop":
          return "Application is only available on mobile devices through Telegram";
        case "ios":
        case "android":
          return "Please open the application through Telegram";
        default:
          return "Application is only available in Telegram";
      }
    } else {
      return "Application is currently in testing phase";
    }
  };

  const getDescription = () => {
    if (isDeviceError) {
      switch (deviceType) {
        case "desktop":
          return "Scan the QR code with your mobile device or click the link below";
        case "ios":
        case "android":
          return "Click the button below to open the app in Telegram";
        default:
          return "Click the link below to open the application";
      }
    } else {
      return "Subscribe to our group to be the first to know about the app launch";
    }
  };

  const getButtonText = () => {
    return isDeviceError ? "Open in Telegram" : "Subscribe to Group";
  };

  const getRedirectUrl = () => {
    return isDeviceError ? telegramAppUrl : telegramGroupUrl;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={logo} alt="Logo" className={styles.logo} />

        <h1 className={styles.title}>{getMessage()}</h1>

        {(deviceType === "desktop" || !isDeviceError) && (
          <div className={styles.qrWrapper}>
            <img
              src={QR}
              alt="QR Code"
              className={styles.qrCode}
              style={{ width: "15vw", height: "15vw" }}
            />
          </div>
        )}

        <p className={styles.description}>{getDescription()}</p>

        <a
          href={getRedirectUrl()}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {getButtonText()}
        </a>
      </div>
    </div>
  );
};

export default PCRedirect;
